@use "../config/" as *;
:root {
  --card-status-width: 16.5rem;
  --card-status-left-gap: var(--space-3);
}
.waf-showcase-card {
  max-width: 52rem;
  width: calc(100% - var(--space-8));
  z-index: var(--z-showcase-card);
  // transform: translate(-50%, 0);
  // min-height: 28.3rem;
  // @include position(null, null, calc(var(--window-inner-height) - 65rem), 50%);
  margin: -10rem auto 2rem;
  position: relative;
  &.waf-component {
    @extend %p-0;
  }
  .layout-wrapper {
    max-width: unset;
    margin-inline: 0;
  }
  .waf-head {
    @extend %d-none;
  }
  .card {
    &-item {
      min-height: 27rem;
      justify-content: center;
      background: linear-gradient(225deg, var(--primary-90) 0%, var(--primary-50) 100%);
      @extend %p-6;
      @extend %full-radius;
      @extend %flex-column;
      @extend %relative;
      @extend %neutral-5;
    }
    &-status {
      max-width: var(--card-status-width);
      @include position(9.2rem, var(--space-6));
      @extend %flex-column;
    }
    &-thumbnail {
      .img-box {
        @extend %bg-transparent;
      }
    }
    &-image {
      width: auto;
      height: 3.7rem;
      @extend %relative;
    }
    &-body {
      margin-top: 3.5rem;
    }
    &-label {
      width: max-content;
      @extend %mb-1;
      @extend %font-12;
    }
    &-title {
      width: calc(100% - var(--card-status-width) - var(--card-status-left-gap));
      margin-bottom: 3.2rem;
      @include truncate-text(3);
      @extend %title-60;
      a {
        @extend %neutral-5;
      }
    }
    &-btn {
      &:last-child {
        @extend %ml-auto;
      }
    }
  }
  .card-btn-group {
    @extend %flex-sb-c;
  }
  .btn-site {
    @extend %btn;
    &:not(.btn-more) {
      @include tag-config-1(outline, text, neutral-5, neutral-5);
    }
    &.btn-more {
      border: 0;
      @extend %d-block;
      &:hover {
        &::before {
          @extend %bg-primary-55;
        }
      }
      &:focus {
        &::before {
          @extend %bg-primary-85;
        }
      }
      &::before {
        width: 4.8rem;
        height: 4.8rem;
        @include icon(arrow-right, 24);
        @extend %circle-radius;
        @extend %flex-c-c;
        @extend %bg-primary-60;
        @extend %neutral-0;
      }
      .text {
        @extend %font-0;
      }
    }
  }
  .label {
    @extend %mb-1;
    @extend %font-12;
  }
  .counter {
    @extend %flex;
    &-item {
      &.day {
        border-right: .3rem solid var(--secondary-50);
        @extend %pr-2;
        @extend %mr-2;
        @extend %relative;
      }
      &:not(.day, .sec) {
        @extend %mr-4;
        .number {
          @extend %relative;
          &::after {
            content: ":";
            @include position(0, var(--space-3-neg));
            @extend %h-100;
            @extend %flex-n-c;
            @extend %title-50;
          }
        }
      }
    }
    .number {
      width: max-content;
      display: inline-block;
      @extend %mb-1;
      @extend %title-50;
    }
    .text {
      line-height: 1;
      @extend %font-12;
      @extend %text-center;
    }
  }
  .live {
    .status {
      width: max-content;
      background-color: hsl(var(--hsl-neutral-100) / .2);
      @include position(-6.9rem, 0);
      @extend %p-2-3;
      @extend %rounded-radius;
      .text {
        gap: var(--space-1);
        @extend %flex-n-c;
        @extend %neutral-5;
        @extend %text-mb;
        &::after {
          font-weight: 400;
          @include icon(live, 16);
        }
      }
    }
  }
  .live,
  .recent {
    .card-label,
    .card-title {
      width: 100%;
    }
  }
}
@media screen and (max-width: $sm-mobile-max-width) {
  :root {
    --card-status-width: 15.5rem;
  }
  .waf-showcase-card {
    .card-item {
      padding-inline: var(--space-3);
    }
    .card-status,
    .card-action {
      right: var(--space-3);
    }
    .counter .number {
      font-size: 1.6rem;
    }
  }
}
@include mq(col-tablet) {
  .waf-showcase-card {
    width: 45%;
    min-width: 37.5rem;
    position: absolute;
    bottom: 15rem;
    right: var(--container-white-space);
    min-height: 27.6rem;
    .card-title {
      font-size: 1.8rem;
      margin-bottom: 2.8rem;
    }
  }
}
@include mq(col-xl) {
  .waf-showcase-card {
    right: var(--xl-container-white-space);
    bottom: 18rem;
    &-title {
      font-size: 2rem;
      line-height: 1.4;
    }
    &:not(:has(.card-thumbnail)) {
      .card-status {
        top: 6.5rem;
      }
    }
  }
}