@use '../config/' as *;
.waf-showcase {
    &:not(:has(.waf-showcase-card)) {
        .preview-swiper {
            .article-content {
                bottom: 9rem;
            }
            .swiper-pagination {
                bottom: 5rem;
            }
        }
        .showcase-wrapper {
            margin-bottom: var(--space-0);
        }
    }
    .showcase-wrapper {
        // height: calc(var(--window-inner-height) - 12rem);
        height: 70rem;
    }
    .preview-swiper {
        .swiper-pagination {
            bottom: 13rem;
        }
        .article-content {
            bottom: 16rem;
        }
    }
    &.waf-home-showcase {
        .preview-swiper {
            .article {
                &-thumbnail,
                &-wrap {
                    @extend %h-100;
                }
            }
        }
    }
}
@include mq(col-tablet) {
    .waf-showcase {
        &:not(:has(.waf-showcase-card)) {
            .preview-swiper {
                .article-content {
                    bottom: 17rem;
                }
            }
        }
        .preview-swiper {
            // .article-content > a,  //need clarification
            // .article-description {
            //     width: 45%;
            // }
            .swiper-pagination {
                bottom: unset;
            }
            .article-content {
                width: calc(var(--container-max-width) - 38rem);
                bottom: 25%;
                left: var(--container-white-space);
                transform: unset;
            }
            .article-title {
                line-height: 1.3;
            }
        }
        .showcase-wrapper {
            margin-bottom: var(--space-0);
            height: 80rem;
        }
    }
}
@include mq(col-lg) {
    .waf-showcase {
        &:not(:has(.waf-showcase-card)) {
            .preview-swiper {
                .article-content {
                    bottom: 20rem;
                }
            }
        }
        .preview-swiper {
            .article-content {
                width: calc(var(--container-max-width) - 46%);
            }
        }
    }
}
@include mq(col-desktop) {
    .waf-showcase {
        .preview-swiper {
            .article {
                &-content {
                    width: calc(var(--container-max-width) - 53rem);
                }
                &-title {
                    -webkit-line-clamp: 3;
                }
            }
        }
    }
}
@include mq(col-xl) {
    .waf-showcase {
        .preview-swiper {
            .article-content {
                left: var(--xl-container-white-space);
            }
        }
    }
}