@use './config/' as *;
@forward './main.scss';
@forward './listing/listing-common';
@forward './module/banner';
@forward './module/event-banner';
@forward './standings/standings';
@forward './showcase/showcase';
@forward './showcase/home-showcase';
@forward './swiper/swiper-bundle';
@forward './events/eventstrip';
@forward "./events/showcase-eventlisting";
@forward './standings/livestrip';
.waf-row-event {
    &::after {
        bottom: -0.1rem;
    }
}
.waf-listing {
    &.waf-multimedia-listing {
        .preview-swiper {
            @extend %mr-4-neg;
        }
        .article {
            &-list {
                & > * {
                    width: calc(100% / 1.4 - ((1.4 - 1) * var(--space-4) / 1.4));
                }
            }
        }
    }
}
.waf-ranking {
    .head-tab {
        @extend %d-none;
        a {
            max-width: 28rem;
        }
    }
}
.load-bg-pattern {
    .waf-ranking {
        @include bgImg("svg/noise-gradient-mob.svg", 56% 96%, cover);
    }
    .waf-row-event {
        @include background(hsl(var(--hsl-neutral-100) / .5), "cssimages/event-listing-bg-mob.webp", center / cover no-repeat);
    }
}
@include mq(col-tablet) {
    .waf-listing {
        &.waf-multimedia-listing {
            .preview-swiper {
                margin-right: 0;
            }
            .article {
                &-list {
                    & > * {
                        width: 100%;
                    }
                }
            }
        }
    }
    .waf-ranking {
        .head-tab {
            display: block;
        }
        // .table-body {
        //     .table-row {
        //         transition: background-color 0.2s linear;
        //         &:hover {
        //             background-color: var(--neutral-10);
        //         }
        //     }
        // }
    }
    .load-bg-pattern {
        .waf-row-event {
            @include background(hsl(var(--hsl-neutral-100) / .5), "cssimages/event-listing-bg.webp", center / cover no-repeat);
        }
        .waf-ranking {
            @include bgImg("svg/noise-gradient.svg", center, cover);
        }
    }
}
@include mq(col-desktop) {
    .waf-listing {
        &.waf-horizontal-list {
            .article {
                &-wrap,
                &-thumbnail {
                    height: 100%;
                }
                &-title {
                    -webkit-line-clamp: 4;
                    line-clamp: 4;
                }
                &-thumbnail {
                    a {
                        height: 100%;
                        display: block;
                    }
                    .img-box {
                        height: 100%;
                    }
                }
            }
        }
    }
    .homepage {
        .waf-banner.waf-support-banner {
            .banner-wrapper {
                width: 100%;
            }
            .banner-image {
                object-fit: cover;
                object-position: bottom;
            }
        }
    }
}