@use "../config/" as *;
.waf-livestrip.waf-component {
    @extend %p-0;
}
.waf-livestrip {
    position: absolute;
    top: 0;
    left: 0;
    @extend %w-100;
    @extend %zindex-livestrip;
    .title {
        height: calc(var(--livestrip-height) / 2);
        @extend %mb-0;
        @extend %flex;
        @extend %x-auto;
    }
    .livestrip-title {
        white-space: nowrap;
        @extend %body-mb;
        @extend %neutral-0;
    }
    .status {
        background: hsl(var(--hsl-neutral-20)/0.3);
        border-radius: 10rem;
        min-height: 3.2rem;
        @extend %capitalize;
        @extend %neutral-0;
        @extend %text-mb;
        @extend %flex-n-c;
        @extend %gap-2;
        @extend %p-2;
        &:after {
            font-weight: 400;
            @include icon(live, 16);
        }
    }
    .table {
        height: var(--livestrip-height);
        background: hsl(var(--hsl-neutral-0)/0.2);
        @extend %mr-2-neg;
        &-wrap,
        &-body {
            @extend %px-2;
        }
        &-wrap {
            height: 6.8rem;
            @extend %flex-n-c;
            @extend %gap-2;
            @extend %bg-primary-50;
            @extend %p-2;
        }
        &-title {
            @extend %body-sb;
            @extend %neutral-0;
            @extend %px-2;
            @extend %mb-1;
            @include truncate-text(2);
        }
        &-body {
            width: var(--window-inner-width);
            height: 5.6rem;
            @extend %x-auto;
            @extend %flex-n-c;
            @extend %gap-2;
        }
        &-row {
            border-radius: 4.8rem;
            height: 3.2rem;
            min-width: max-content;
            width: max-content;
            @extend %bg-neutral-0;
            @extend %gap-1;
            @extend %px-2;
        }
        &-row-wrapper {
            @extend %flex-n-c;
        }
        &-data {
            flex-shrink: 1;
            @extend %px-1;
            @extend %h-100;
            .text {
                @extend %neutral-100;
            }
        }
        .ranking {
            width: 3rem;
        }
        .info {
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
        .points,
        .time {
            width: max-content;
            .text {
                @extend %body-s;
                @extend %neutral-70;
                @extend %text-right;
            }
        }
    }
    .card {
        &-thumbnail {
            @extend %d-none;
        }
        &-name {
            @extend %mb-0;
            .text {
                @extend %d-block;
                @extend %body-mb;
                @extend %text-left;
            }
        }
        &-logo {
            width: 1.6rem;
            height: 1.6rem;
            aspect-ratio: 1/1;
            @extend %mr-1;
            @extend %circle-radius;
        }
    }
    .title {
        &-name {
            @extend %text-s;
            @extend %neutral-5;
            @extend %px-2;
        }
        &-wrapper {
            width: 29rem;
        }
    }
    .upcoming {
        .status {
            &::after {
                content: unset;
            }
        }
    }
}
.waf-showcase {
    .waf-livestrip {
        .swiper-button {
            &-prev,
            &-next {
                width: 6.4rem;
                height: 6.4rem;
                aspect-ratio: 1/1;
                display: flex;
                border: unset;
                border-radius: unset;
                top: 0;
                @extend %bg-primary-50;
                &:after {
                    width: 3.2rem;
                    height: 3.2rem;
                    @include border(1, neutral-0);
                    @extend %circle-radius;
                    @extend %flex-c-c;
                }
            }
            &-next {
                right: 0;
                &::after {
                    @include icon(arrow-small-right);
                }
            }
            &-prev {
                left: 0;
                &::after {
                    @include icon(arrow-small-left);
                }
            }
        }
        &:has(.swiper-button-prev, .swiper-button-next) {
            .table {
                &-wrap {
                    padding-left: 6.5rem;
                }
                &-title {
                    @extend %px-0;
                }
            }
            .title {
                &-wrapper {
                    width: 18rem;
                }
                &-name {
                    @extend %px-0;
                }
            }
        }
    }
}
@include mq(col-tablet) {
    .waf-livestrip {
        margin: 0;
        .layout-wrapper {
            display: flex;
            height: 100%;
        }
        .head-wrap {
            gap: var(--space-4);
        }
        .title,
        .title-wrapper {
            width: auto;
        }
        .livestrip-title {
            white-space: inherit;
            font-size: 1.6rem;
            @include truncate-text(2);
        }
        .table {
            display: flex;
            overflow-x: unset;
            height: var(--livestrip-height);
            flex-direction: row;
            &-list {
                width: var(--window-inner-width);
            }
            &-wrap {
                height: var(--livestrip-height);
                width: 40rem;
            }
            &-body {
                gap: var(--space-4);
                width: calc(100% - 40rem);
                height: 100%;
                overflow-x: auto;
                @include custom-scroll();
            }
            &-title {
                font-size: 1.4rem;
            }
            &-row {
                &.neutral {
                    opacity: 1;
                    transition: opacity 0.2s linear;
                    &:hover {
                        opacity: 0.9;
                    }
                }
            }
        }
    }
    .waf-showcase {
        .waf-livestrip {
            .swiper-button {
                &-prev,
                &-next {
                    width: 8.8rem;
                    height: 8.8rem;
                    &::after {
                        width: 4.2rem;
                        height: 4.2rem;
                    }
                }
            }
            &:has(.swiper-button-prev, .swiper-button-next) {
                .table {
                    &-wrap {
                        width: 48rem;
                        padding-left: 9rem;
                    }
                    &-title {
                        padding-inline: var(--space-2);
                    }
                    &-body {
                        width: calc(100% - 56rem);
                    }
                }
                .title {
                    &-wrapper {
                        width: auto;
                    }
                    &-name {
                        padding-inline: var(--space-2);
                    }
                }
            }
        }
    }
}